import { useMemo } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { isAfter, isBefore, toDate, parseISO, format } from "date-fns"

import Footer from "components/Footer"
import { NewsNavi } from "components/NewsNavi"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { NoNewsFound } from "components/NoNewsFound"
import { NoTranslationFound } from "components/NoTranslationFound"

/**
 * ニュースの詳細
 * gatsby-node で生成されるニュース詳細ページのテンプレート
 * url: /news/article/:contentful_id
 * newsContent が markdown で格納される
 */
const ArticleTemplate = ({ pageContext }) => {
  const { locale } = useLocalization()

  const { t } = useTranslation()
  const now = toDate(Date.now())

  const localizedArticle = pageContext.nodes.find(
    node => node.node_locale === locale && node.newsContent.newsContent !== "",
  )

  // locale のデータがあったら範囲をチェック
  const withinInterval = useMemo(() => {
    if (localizedArticle === undefined) return false

    const notUnpublished = isBefore(
      now,
      parseISO(localizedArticle.newsMeta?.end_publish_datetime),
    )
    const published = isAfter(
      now,
      parseISO(localizedArticle.newsMeta?.start_publish_datetime),
    )

    return notUnpublished && published
  }, [localizedArticle])

  /**
   * locale での newsContent が空でない article が
   * ある
   *  掲載期間中で
   *    ある
   *      ニュースを表示する
   *    ない
   *      NoNewsFound
   * ない
   *  -> NoTranslationFound
   */
  const showNews = useMemo(() => {
    if (localizedArticle === undefined) {
      return <NoTranslationFound />
    }

    if (localizedArticle) {
      if (withinInterval) {
        return (
          <div className="main_area inviewfadeInUp">
            <p className="date">
              {format(
                parseISO(localizedArticle.newsMeta.post_date),
                t("yyyy年MM月dd日"),
              )}
            </p>
            <article
              className="news_detail inviewfadeInUp fadeInUp"
              dangerouslySetInnerHTML={{
                __html: localizedArticle.newsContent.childMarkdownRemark.html,
              }}
            />
          </div>
        )
      } else {
        return <NoNewsFound />
      }
    }
  }, [localizedArticle])

  return (
    <main>
      <Helmet title={t("ニュース")} />
      <div className="sub_page">
        <section className="page_header news inviewfadeInUp">
          <div className="container2">
            <h1>{t("ニュース")}</h1>
          </div>
        </section>

        <div className="container3">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                <Link to="/news">{t("ニュース一覧")}</Link>
              </li>
              <li>{localizedArticle?.title}</li>
            </ul>
          </section>
          <section className="content">
            {showNews}
            <NewsNavi />
          </section>
        </div>
      </div>
      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>
      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default ArticleTemplate
